import React, { useEffect, useRef, useState, useContext } from "react";
import Link from 'next/link'

export const Footer = ({
}) => {
  const [subdomain, setSubdomain] = useState<null|string>();

  useEffect(() => {
    const subdomainMatch = window.location.href.match(/^(?:https?:\/\/)?([^\/]+)\./);
    if (subdomainMatch && subdomainMatch.length > 1 && subdomainMatch[1] !== 'm-newsletter') {
      setSubdomain(subdomainMatch[1])
    }
  }, []);

  return (
    <div className="w-full border-t relative mt-40 px-3 md:px-0 ">
      <div className="max-w-2xl mx-auto my-10 flex items-start md:grid grid-cols-2  place-items-center text-gray-600">
        <div className="list-none text-sm flex-1">
          <ul className="list-none">
            <li className="h-8">
              <Link href="/" className="hover:underline">
                トップページ
              </Link>
            </li>
            <li className="h-8">
              <Link href="/about" className="hover:underline">
                ABOUT
              </Link>
            </li>
            <li className="h-8">
              <Link href="/faq" className="hover:underline">
                よくあるご質問
              </Link>
            </li>
            <li className="h-8">
              <a
                href="https://m-newsletter.canny.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
              >
                開発へのご要望
              </a>
            </li>
            <li className="h-8">
              <Link href="/contact" className="hover:underline">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
        <div className="text-left text-sm flex-1">
          <ul className="list-none ">
            <li className="h-8">
              {subdomain ? (
                <Link href="/legals/terms" className="hover:underline">
                  利用規約
                </Link>
              ) : (
                <Link
                  href="https://jabba.m-newsletter.com/legals/terms"
                  className="hover:underline"
                >
                  利用規約
                </Link>
              )}
            </li>
            <li className="h-8">
              {subdomain ? (
                <Link href="/legals/privacy" className="hover:underline">
                  プライバシーポリシー
                </Link>
              ) : (
                <Link
                  href="https://jabba.m-newsletter.com/legals/privacy"
                  className="hover:underline"
                >
                  プライバシーポリシー
                </Link>
              )}
            </li>
            <li className="h-8">
              {subdomain ? (
                <Link
                  href="/legals/CommerceDisclosure"
                  className="hover:underline"
                >
                  特定商取引法に基づく表記
                </Link>
              ) : (
                <Link
                  href="https://jabba.m-newsletter.com/legals/CommerceDisclosure"
                  className="hover:underline"
                >
                  特定商取引法に基づく表記
                </Link>
              )}
            </li>
            <li className="h-8">
              サーバーステータス
              <ul className="text-xs mt-2 ml-3">
                <li className="h-6">
                  <a
                    href="https://m-newsletter-api.betteruptime.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    APIバックエンド
                  </a>
                </li>
                <li className="">
                  <a
                    href="https://m-newsletter.betteruptime.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    フロントエンド
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div className="py-12 mx-auto">
        <Link href={process.env.NEXT_PUBLIC_FRONT_URL}>
          <img
            src="/images/mail.svg"
            className="mx-auto w-12"
            width={48}
            height={48}
            alt="mail"
          />
          <img
            src="/images/title.webp"
            className="mx-auto w-48"
            alt="みんなのニュースレター"
            width={384}
            height={37}
          />
        </Link>
      </div>
    </div>
  );
}

export default Footer;
