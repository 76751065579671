import { initializeGraphQL } from '../../../lib/graphql-client'
import graphQLRequest from '../../../lib/graphql-request'
import Newsletter, {
  NEWSLETTER_QUERY,
  userQueryOptions,
} from '../../../components/Newsletter'
import { useRouter } from 'next/router'
import axios from 'axios';

export const NewsletterPage = props => {
  const router = useRouter()

  return(
    <Newsletter
      subdomain={router.query['subdomain']}
      key={router.asPath}
    />
  )
}
export default NewsletterPage

export async function getStaticProps({ params }) {
  const client = initializeGraphQL()

  await graphQLRequest(client, NEWSLETTER_QUERY, userQueryOptions(params.subdomain))

  return {
    props: {
      initialGraphQLState: client.cache.getInitialState(),
    },
    revalidate: 6,
  }
}

export async function getStaticPaths() {
  // const res = await axios.get(process.env.NEXT_PUBLIC_API + '/id/newsletters?prioritize=true')
  // const newsletters = await res.data

  // let paths = []
  // for(let i in newsletters) {
  //   paths.push({
  //     params: { subdomain: newsletters[i].subdomain }
  //   })
  // }
  return { paths: [], fallback: 'blocking' }
}
